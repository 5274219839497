import React from "react"
import { AtollonProvider } from "../../plugins/gatsby-plugin-atollon"
import layoutQuery from "../hooks/use-layout-query"

import "../assets/scss/main.scss"

import Header from "../components/header"
import Footer from "../components/footer"

import Lenis from "@studio-freight/lenis"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const Layout = (props) => {

  const globalData = layoutQuery()

  React.useEffect(() => {
      const lenis = new Lenis()
      lenis.on("scroll", (e) => {})
      lenis.on("scroll", ScrollTrigger.update)
      const updateLenis = (time) => {
        lenis.raf(time * 1000)
      }
      gsap.ticker.add(updateLenis)
      gsap.ticker.lagSmoothing(0)

      return () => {
        gsap.ticker.remove(updateLenis)
        lenis.destroy()
      }
    }, [props.location.pathname]);

  return (
    <AtollonProvider {...props} globalData={globalData}>
      <Header header={props.pageContext.header} />
      <main>
        {props.children}
      </main>
      <Footer subscribeForm={globalData.subscribeForm} />
    </AtollonProvider>
  )
}

export default Layout
