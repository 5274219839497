module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.2,"rootMargin":"0px 0px 0px 0px"},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Darling Group","short_name":"Darling Group","start_url":"/","background_color":"#242322","theme_color":"#242322","display":"minimal-ui","icon":"/opt/build/repo/src/assets/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2b6e870572488e5fe22e2b63853da314"},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://darlinggroup.com.au","stripQueryString":true},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/gatsby-browser.js'),
      options: {"plugins":[],"disableLiveReload":true,"datoCmsFullAccessApiKey":"cf80e6cff9ebd4d8565e78b3d22e86","datoCmsUrl":"https://darling-group.admin.datocms.com","draftsUrl":"https://darling-group-draft.netlify.app","productionUrl":"https://darlinggroup.com.au","site":{"name":"Darling Group","defaultMetaDescription":"Experience true hospitality across Melbourne’s finest restaurants.","facebookPageUrl":"https://www.facebook.com/darlinghospitality/","twitterAccount":"@atollon","primaryColor":"#242322","lightColor":"#FFFFFF","accentColor":"#51453E","darkColor":"#242322","icon":"src/assets/images/favicon.svg"},"email":{"host":"ventraip.email","port":465,"username":"noreply@mailer.atollon.com.au","password":"dvr-mka6FJF!nat!jaf","sender":"noreply@mailer.atollon.com.au"},"connectors":[],"locales":[],"models":[{"name":"Pages","apiKey":"page","apiKeyMultiple":"pages","type":"page","hasBlocks":true,"isDefaultPageType":true,"pageContext":"\n              header\n            "},{"name":"Venues","apiKey":"venue","apiKeyMultiple":"venues","type":"page","hasBlocks":true,"isDefaultPageType":true},{"name":"Jobs","apiKey":"job","apiKeyMultiple":"jobs","type":"page","hasBlocks":false,"isDefaultPageType":false,"permalink":true},{"name":"Events","apiKey":"event","apiKeyMultiple":"events","type":"page","hasBlocks":false,"isDefaultPageType":false,"permalink":false},{"name":"Header","apiKey":"headerMenuItem","apiKeyMultiple":"headerMenuItems","type":"menu"},{"name":"Footer","apiKey":"footerMenuItem","apiKeyMultiple":"footerMenuItems","type":"menu"},{"name":"Site Options","apiKey":"siteOptionPage","apiKeyMultiple":"siteOptionPages","type":"site-options"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MG7WQX5K","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
