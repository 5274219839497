import { useStaticQuery, graphql } from "gatsby"

const useVenuesQuery = () => {
  const { allDatoCmsVenue } = useStaticQuery(graphql`
      query Venues {
        allDatoCmsVenue(sort: {position: ASC}) {
          nodes {
            title
            slug
            permalink
            phone
            richContent {
              value
            }
            email
            jsonLd
            location {
              value
            }
            openingHours {
              value
            }
            socialLinks {
              ...LinkWithTitleProps
            }
            viewMenuLink
            buttons {
              ...LinkWithTitleProps
            }
            featuredImage {
              ...InternalAssetProps
            }
          }
        }
      }
  `)
  return allDatoCmsVenue.nodes
}

export default useVenuesQuery