import React, { useState } from "react"
import { Logo, LogoShort, LogoLong } from "./icons"
import { Link, Asset } from "../../plugins/gatsby-plugin-atollon"
import useVenuesQuery from "../hooks/use-venues-query"
import IMG from "../assets/images/IMG-01.jpg"


const Header = ({ header }) => {
  const venues = useVenuesQuery()

  const [offCanvas, setOffCanvas] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const [sticky, setSticky] = React.useState(false)
  const [offCanvasMenu, setOffCanvasMenu] = React.useState({
    subActive: false,
    menu: [],
    initial: false,
  })
  const [dropdownMenu, setDropdownMenu] = React.useState({
    dropdownActive: false,
    menu: [],
    initial: false,
  })
  const [current, setCurrent] = useState(null)
  const [headerVenue, setHeaderVenue] = useState(false)
  const [direction, setDirection] = React.useState('down')
  const headerRef = React.useRef()


  const closeMenu = () => {
    setHeaderVenue(false)
    setOffCanvasMenu({
      subActive: false,
      menu: [],
      initial: false,
    })
    setDropdownMenu({
      dropdownActive: false,
      menu: [],
      initial: false,
    })
    setOffCanvas(false)
  }

  let isScrolling
  let prevScroll = 0

  const onScroll = (e) => {

    let scr, hhScrolled

    const curScroll = window.scrollY || document.scrollTop
    const stickyHeader = document?.querySelector(".sticky-header")
    scr = curScroll > 1
    const dir = curScroll > prevScroll ? "down" : "up"

    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return

    if (stickyHeader) {
      if (direction === "down") {
        setSticky(true)
        stickyHeader.classList.add("active")
      } else {
        setSticky(false)
        stickyHeader.classList.remove("active")
      }
    } else {
      setSticky(false)
    }

    prevScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
      setDirection(dir)
    })
  }

  const openHeaderVenue = () => {
    closeMenu()
    setHeaderVenue(true)
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  React.useEffect(() => {
    window.addEventListener("scroll", scrollDetect, false)
    return () => {
      window.removeEventListener("scroll", scrollDetect, false)
    }
  }, [])

  let props = {
    onClick: () => closeMenu(),
    activeClassName: "active",
  }

  let headerClass = "header"
  if (scrolled) headerClass += " header--scrolled"
  if (offCanvas) headerClass += " header--off-canvas"
  if (headerVenue) headerClass += " header--venue"
  if (sticky) headerClass += " header--sticky"
  if (dropdownMenu.dropdownActive) headerClass += " header--dropdown-active"
  if (header) headerClass +=  ` header--${header}`
  if (headerVenue) headerClass += ` header--active`
  if ( direction === 'down' && scrolled ) headerClass += ' header--up'


  return (
    <>
      <header className={headerClass}>
        <div className="header__inner">
          {headerVenue && <button onClick={() => {
            setOffCanvas(!offCanvas)
            setHeaderVenue(!headerVenue)
          }} className={`header__back`}>MAIN</button>}
          <nav className="header__nav header__nav--left">
            <ul>
              <li>
                <button
                  onClick={() => headerVenue ? closeMenu() : openHeaderVenue()}>{headerVenue ? "Close" : "Venues"}</button>
              </li>
              <li><Link to="/events/" {...props}>Events</Link></li>
              <li><Link to="/vouchers/" {...props}>Vouchers</Link></li>
            </ul>
          </nav>
          <div className={`header__logo ${headerVenue ? 'active' : ''}`}>
            <Link className='logo' to="/" {...props}><Logo /></Link>
            <Link className='logo--long' to="/" {...props}><LogoLong /></Link>
            <Link className='logo--short' to="/" {...props}><LogoShort /></Link>
          </div>
          <nav className="header__nav header__nav--right">
            <ul>
              <li><Link to="/careers/" {...props}>Careers</Link></li>
              <li><Link to="/about-us/" {...props}>About</Link></li>
              <li><Link to="/contact/" {...props}>Contact</Link></li>
            </ul>
          </nav>
          <button onClick={() => headerVenue ? closeMenu() : setOffCanvas(!offCanvas)} className={offCanvas ? "header__hamburger active" : "header__hamburger"}>
            {(offCanvas || headerVenue) ? 'CLOSE' : 'MENU'}
          </button>
        </div>
      </header>

      <div
        className={`header__venues ${headerVenue ? "active" : ""} header__venues--${venues[current] ? venues[current].slug : "default"}`}>
        <div className="header__venues-inner">
          <ul className="header__venues-list">
            {venues.map((venue, index) => (
              <li key={index}
                  className={`${current !== null && current !== index ? 'inactive' : ''}`}
              >
                <Link onMouseLeave={() => setCurrent(null)}
                      onMouseEnter={() => setCurrent(index)}
                      {...props}
                      to={venue.permalink}>
                  {venue.title}
                </Link>
              </li>
            ))}
          </ul>
          <div className="header__venues-images">
            <div className={`header__venues-image ${null === current && headerVenue ? "active" : ""}`}>
              <div className="gatsby-image-wrapper">
                <img loading="lazy" src={IMG} alt="Darling Group" />
              </div>
            </div>
            {venues.map((venue, index) => (
              <div key={index} className={`header__venues-image ${index === current ? "active" : ""}`}>
                <Asset asset={venue.featuredImage} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={`off-canvas ${offCanvas ? "active" : ""} ${offCanvasMenu.menu[1] > 0 ? "off-canvas__menu--active" : ""}`}>
        <div className="off-canvas__inner">
          <nav className="off-canvas__nav">
            <ul>
              <li>
                <button onClick={() => openHeaderVenue()}>Venues</button>
              </li>
              <li><Link {...props} to="/events/">Events</Link></li>
              <li><Link {...props} to="/vouchers/">Vouchers</Link></li>
              <li><Link {...props} to="/careers/">Careers</Link></li>
              <li><Link {...props} to="/about-us/">About</Link></li>
              <li><Link {...props} to="/contact/">Contact</Link></li>
            </ul>
          </nav>
        </div>

        <div className='off-canvas__venues'>
          <div className='off-canvas__venues-list'>
            {venues.map((venue, index) => (
              <li key={index}
                  className={`${current !== null && current !== index ? 'inactive' : ''}`}
              >
                <Link onClick={() => setCurrent(index)}
                      {...props}
                      to={venue.permalink}>
                  {venue.title}
                </Link>
              </li>
            ))}
          </div>

          <div className='off-canvas__venues-images'>

          </div>
        </div>
      </div>
    </>
  )
}

export default Header
